import { Inject, Injectable, Optional } from '@angular/core';
import { Request } from 'express';
import { REQUEST } from 'ngx-cookie-service-ssr';

type ExtendedResponse = Response & {
  redirect: (status: number, url: string) => void;
  finished: boolean;
  end: () => void;
  status: (status: number) => ExtendedResponse;
};

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  constructor(
    @Optional() @Inject('RESPONSE') private res: ExtendedResponse,
    @Optional() @Inject(REQUEST) private req: Request & { _r_count?: number },
  ) {}

  public redirect(status: number, url: string): void {
    if (!this.res || !this.req) {
      return;
    }

    if (this.req.url === url) {
      return;
    }

    if (!this.res.finished) {
      this.res.redirect(status, url);
      this.res.finished = true;
      this.res.end();
    } else {
      this.onRedirectOnFinishedResponse(url);
    }
  }

  public set404(): void {
    this.res.status(404);
  }

  public getAcceptLanguages(): string[] {
    if (!this.req) {
      return [];
    }

    return Array.from(new Set(this.req.acceptsLanguages().map((l) => l.slice(0, 2))));
  }

  private onRedirectOnFinishedResponse(url: string): void {
    this.req._r_count = (this.req._r_count || 0) + 1;
    console.warn('Redirect on a finished response', this.req.url, url);

    if (this.req._r_count > 10) {
      console.error('Redirection loop');
      // tslint:disable-next-line
      console.trace();
      console.log(url);
    }
  }
}
