import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AddLocalePipe } from '../pipes/url-locale.pipe';
import { AuthenticationService } from '../services/authentication.service';

export const isNotLoggedInGuard: CanActivateFn = () => {
  const router = inject(Router);
  const addLocale = inject(AddLocalePipe);
  const auth = inject(AuthenticationService);

  return !auth.isLoggedIn() || router.parseUrl(`${addLocale.transform('panel')}`);
};
