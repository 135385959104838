import { ITaxon, JsonApiDocument, TaxonAttr } from '../services/spree-client/storefront';
import { Category } from '../pages/product/product-list/product-list.types';

export function taxonToCategory(taxonOrTaxonAttr: ITaxon | TaxonAttr): Category {
  let data: TaxonAttr;
  let taxonImage: JsonApiDocument | undefined;

  if (isTaxon(taxonOrTaxonAttr)) {
    data = taxonOrTaxonAttr.data;
    taxonImage = taxonOrTaxonAttr.included?.find((i) => i.type === 'taxon_image');
  } else {
    data = taxonOrTaxonAttr;
  }

  const category: Category = {
    id: data.id,
    name: data.attributes.name,
    description: data.attributes.description ?? '',
    metaTitle: data.attributes.meta_title ?? '',
    metaDescription: data.attributes.meta_description ?? '',
    localizedSlugs: data.attributes.localized_slugs,
    prettyName: data.attributes.pretty_name ?? '',
    permaLink: data.attributes.permalink ?? '',
  };

  const image = {} as { url: string; alt: string };

  if (taxonImage) {
    image.url = (taxonImage.attributes['original_url'] as string | undefined) ?? '';
    image.alt = (taxonImage.attributes['alt'] as string | null) ?? '';
    category.image = image;
  }

  return category;
}

function isTaxon(x: ITaxon | TaxonAttr): x is ITaxon {
  return x != undefined && typeof x === 'object' && 'data' in x;
}
