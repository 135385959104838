import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { CartService } from '../pages/cart-page/cart.service';
import { AddLocalePipe } from '../pipes/url-locale.pipe';
import { map } from 'rxjs';

export const CheckoutGuard: CanActivateFn = () => {
  const cart = inject(CartService);
  const router = inject(Router);
  const addLocale = inject(AddLocalePipe);

  return cart.itemCount$.pipe(
    map((itemCount) =>
      itemCount > 0 ? true : router.parseUrl(`${addLocale.transform('/')}`),
    ),
  );
};
