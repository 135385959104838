import {
  JsonApiDocument,
  LocalizedSlugs,
  RelationType,
} from '../services/spree-client/core';
import { LineItem } from '../pages/cart-page/cart.types';
import { Image } from '../pages/product/product-list/product-list.mapper';
import { OrderAttr, TaxonAttr } from '../services/spree-client/storefront';
import { taxonToCategory } from './category.serializer';

export function deserializeLineItem(
  lineItem: JsonApiDocument,
  attachments: JsonApiDocument[],
): LineItem {
  const variantId = (lineItem.relationships['variant'].data as RelationType).id;
  const variant = findAttachment(attachments, variantId, 'variant')!;
  const productId = (variant.relationships['product'].data as RelationType).id;
  const product = findAttachment(attachments, productId, 'product');
  const image: Image | null = findImagesBasedOnVariantId(attachments, variantId);
  const taxonIds = ((product?.relationships['taxons'].data ?? []) as RelationType[]).map(
    (t) => t.id,
  );
  const taxons = taxonIds
    .map((id) => findAttachment(attachments, id, 'taxon'))
    .filter(Boolean) as TaxonAttr[];
  const categories = taxons.map((taxon) => taxonToCategory(taxon));

  return {
    id: parseInt(lineItem.id, 10),
    variantId: parseInt(variant.id, 10),
    productId: parseInt(productId, 10),
    description: lineItem.attributes['description'] as string,
    name: lineItem.attributes['name'] as string,
    sku: variant.attributes['sku'] as string,
    slug: lineItem.attributes['slug'] as string,
    localizedSlugs: product
      ? (product.attributes['localized_slugs'] as LocalizedSlugs)
      : null,
    image: image,
    price: {
      original: convertPriceToFloat(variant.attributes['compare_at_price']),
      current: convertPriceToFloat(variant.attributes['price']),
    },
    displayPrice: lineItem.attributes['display_price'] as string,
    displayTotal: lineItem.attributes['display_total'] as string,
    quantity: lineItem.attributes['quantity'] as number,
    options: formatOptions(lineItem.attributes['options_text'] as string),
    optionsText: lineItem.attributes['options_text'] as string,
    purchasable: variant.attributes['purchasable'] as boolean,
    inStock: variant.attributes['in_stock'] as boolean,
    categories,
  };
}

export const convertPriceToFloat = (price: unknown): number => {
  return parseFloat(typeof price === 'string' ? price : '0');
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const findAttachment = (
  attachments: JsonApiDocument[],
  id: string,
  type: string,
): JsonApiDocument | undefined => {
  return attachments.find((e) => e.id === id && e.type === type);
};

export const findImagesBasedOnVariantId = (
  attachments: JsonApiDocument[],
  lineItemId: string,
): Image | null => {
  const product = attachments.find((type) => {
    return (
      type.type === 'product' &&
      (type.relationships['default_variant'].data as RelationType).id === lineItemId
    );
  });

  const variant = attachments.find((type) => {
    return type.type === 'variant' && type.id === lineItemId;
  });

  const metaData = product ? product : variant;

  if (!metaData) {
    return null;
  }

  const imagesData = metaData.attributes['public_metadata'] as { images?: Image[] };

  if (!imagesData.images) {
    return null;
  }

  return imagesData.images[0];
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const formatOptions = (optionsText: string) => {
  const optionsArray = optionsText.split(', ');

  return optionsArray.reduce((options, e) => {
    const key = e.split(': ')[0].toLowerCase();
    const value = e.split(': ')[1];

    return { ...options, [key]: value };
  }, {});
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function filterIncludedLineItems(included: JsonApiDocument[], apiCart: OrderAttr) {
  const cartItemIds = (apiCart.relationships['line_items'].data as RelationType[]).map(
    (l) => l.id,
  );

  return included.filter((e) => e.type === 'line_item' && cartItemIds.includes(e.id));
}
