import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { StateService } from '../services/state.service';
import { filter, map } from 'rxjs';
import { ResponseService } from '../services/response.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

export const localeRedirect: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): ReturnType<CanActivateFn> => {
  const stateService = inject(StateService);
  const response = inject(ResponseService);
  const cookies = inject(SsrCookieService);

  /*
   * APP_INITIALIZER code isn't guaranteed to run before this guard does,
   * so this has to be an observable that filters out empty value
   * and handles the first correct one.
   */

  return stateService.activeLocale$.pipe(
    filter((v) => !!v),
    map((activeLocale) => {
      if (route.url.length && route.url[0].path === activeLocale) {
        return true;
      }

      let preferredLocale: string | null = cookies.get('locale');

      if (!preferredLocale) {
        preferredLocale = getPreferredLocaleFromAcceptLangs(
          stateService.availableLocales,
          response.getAcceptLanguages(),
        );
      }

      response.redirect(301, `/${preferredLocale ?? activeLocale}`);

      return false;
    }),
  );
};

function getPreferredLocaleFromAcceptLangs(
  availableLocales: readonly string[],
  acceptLangs: string[],
): string | null {
  for (let i = 0; i < acceptLangs.length; i++) {
    if (availableLocales.includes(acceptLangs[i])) {
      return acceptLangs[i];
    }
  }

  return null;
}
