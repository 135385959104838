import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SpreeService } from './spree-client/storefront/spree.service';
import { BehaviorSubject, map, Observable, shareReplay, tap } from 'rxjs';
import { AddLocalePipe } from '../pipes/url-locale.pipe';
import { IOAuthToken } from './spree-client/core';
import { TranslateRoutePipe } from '../pipes/translate-route.pipe';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { CookieKey } from '../types/cookie-key';

const TOKEN_REFRESH_TIME_WINDOW = 600000; // 10 mins

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly spree = inject(SpreeService);
  private readonly cookie = inject(SsrCookieService);
  private readonly router = inject(Router);
  private readonly addLocale = inject(AddLocalePipe);
  private readonly translateRoute = inject(TranslateRoutePipe);

  private tokenExpiration = Number.parseInt(this.cookie.get(CookieKey.TokenExpiration));

  private _token = this.cookie.get(CookieKey.AccessToken);
  get token(): string {
    return this._token;
  }

  private readonly _isLoggedIn = new BehaviorSubject(!!this._token);

  isLoggedIn$ = this._isLoggedIn.asObservable();

  login(username: string, password: string): Observable<IOAuthToken> {
    return this.spree.authentication
      .getToken({
        username: username,
        password: password,
      })
      .pipe(
        tap((d) => {
          this.saveAuthData(d);
          this._isLoggedIn.next(true);
        }),
      );
  }

  logout(): void {
    this.revokeAndRemoveTokens();
    this._isLoggedIn.next(false);

    void this.router.navigate([
      this.addLocale.transform(this.translateRoute.transform('/login')),
    ]);
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn.value;
  }

  shouldRefreshToken(): boolean {
    if (Number.isNaN(this.tokenExpiration)) {
      return false;
    }

    return this.tokenExpiration < Date.now();
  }

  refreshAccessToken(): Observable<string> {
    return this.spree.authentication
      .refreshToken({
        bearer_token: this.token,
        refresh_token: this.cookie.get(CookieKey.RefreshToken),
      })
      .pipe(
        tap(this.saveAuthData),
        map((d) => d.access_token),
        shareReplay({ windowTime: TOKEN_REFRESH_TIME_WINDOW, refCount: true }),
      );
  }

  private saveAuthData = (data: IOAuthToken): void => {
    // spree uses seconds, we need milliseconds
    const expirationDate = new Date(
      (data.created_at + data.expires_in) * 1000 - TOKEN_REFRESH_TIME_WINDOW,
    );

    this._token = data.access_token;
    this.tokenExpiration = expirationDate.getTime();
    this.cookie.set(CookieKey.AccessToken, data.access_token, { path: '/' });
    this.cookie.set(CookieKey.RefreshToken, data.refresh_token, { path: '/' });
    this.cookie.set(CookieKey.TokenExpiration, expirationDate.getTime().toString(), {
      path: '/',
    });
  };

  private revokeAndRemoveTokens(): void {
    this.spree.authentication.revokeToken({ token: this.token }).subscribe();
    this.spree.authentication
      .revokeToken({ token: this.cookie.get(CookieKey.RefreshToken) })
      .subscribe();

    this.cookie.delete(CookieKey.RefreshToken, '/');
    this.cookie.delete(CookieKey.AccessToken, '/');
    this.cookie.delete(CookieKey.OrderToken, '/');
    this.cookie.delete(CookieKey.TokenExpiration, '/');
    this._token = '';
    this.tokenExpiration = NaN;
  }
}
