import { Injectable, signal, WritableSignal } from '@angular/core';
import { CheckoutState } from './checkout.types';

@Injectable({
  providedIn: 'root',
})
export class CheckoutStepsService {
  steps: WritableSignal<CheckoutState> = signal({
    billing: 'active',
    delivery: 'inactive',
    payment: 'inactive',
  } as CheckoutState);

  initSteps(): void {
    this.steps.set({
      billing: 'active',
      delivery: 'inactive',
      payment: 'inactive',
    });
  }

  nextStep(): void {
    if (this.steps().billing === 'active') {
      this.steps.set({
        billing: 'done',
        delivery: 'active',
        payment: 'inactive',
      });

      return;
    }

    if (this.steps().delivery === 'active') {
      this.steps.set({
        billing: 'done',
        delivery: 'done',
        payment: 'active',
      });

      return;
    }
  }

  editStep(stepName: 'billing' | 'delivery'): void {
    if (stepName === 'billing' && this.steps().billing === 'done') {
      this.steps.set({
        billing: 'active',
        delivery: 'inactive',
        payment: 'inactive',
      });

      return;
    }

    if (stepName === 'delivery' && this.steps().delivery === 'done') {
      this.steps.set({
        billing: 'done',
        delivery: 'active',
        payment: 'inactive',
      });

      return;
    }
  }
}
