import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AddLocalePipe } from '../pipes/url-locale.pipe';
import { TranslateRoutePipe } from '../pipes/translate-route.pipe';
import { AuthenticationService } from '../services/authentication.service';

export const accountGuard: CanActivateFn = () => {
  const router = inject(Router);
  const addLocale = inject(AddLocalePipe);
  const translateRoute = inject(TranslateRoutePipe);
  const auth = inject(AuthenticationService);

  const tree = router.parseUrl(
    `${addLocale.transform(translateRoute.transform('login'))}`,
  );

  return auth.isLoggedIn() || tree;
};
